* {
    transition: all .3s ease;
}

.doubleContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: 20px;
    margin: 0 auto;

    .first {
        width: 70%;
        padding: 10px;
    
        .aboutContainer {
            width: 100%;
            height: 400px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            overflow: hidden;
            padding: 20px 40px;
            color: #fff;
            position: relative;

            & * {
                position: relative;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                background-color: rgba(0, 0, 0, 0.466);
            }
            
            .typeCourse {
                padding: 7px 15px;
                background-color: #FFC700;
                border-radius: 20px;
                color: #004B8F;
                display: inline-block;
                font-weight: 600;
                letter-spacing: -1px;
            }

            .courseHeading {
                width: 70%;
                margin-top: 15px;
            }

            .courseDescription {
                margin-top: 15px;
                font-size: 14px;

                p {
                    color: #fff !important;
                }
            }

            .starAndView {
                display: flex;
                gap: 30px;
                font-size: 13px;
                margin-top: 20px;
                
                .courseStars img {
                    width: 17px;
                    height: 17px;
                }
            }

            .threeInOne {
                display: flex;
                gap: 15px;
                margin-top: 30px;

                & div a img {
                    width: 22px;
                    height: 22px;
                }

                & div:nth-child(1) a {
                    padding: 9px 25px;
                    background-color: #1D9DA0;
                    border-radius: 20px;
                    color: #fff;
                    font-size: 16px;
                }

                & div:nth-child(2) a {
                    padding: 10px;
                    background-color: #fff;
                    border-radius: 20px;
                }

                & div:nth-child(3) a {
                    padding: 10px;
                    background-color: #FF782D;
                    border-radius: 20px;
                }
            }
        }

        .courseDuration {
            padding: 5px 0;

            .faq {
                margin: 0 auto;
                padding: 0;

                li {
                    list-style: none;
                    background-color: #F5FAFF;
                    padding: 10px;
                    margin: 5px 0;

                    .q {
                        padding: 1em;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;

                        span {
                            font-size: 17px;
                            font-weight: 600;
                        
                            .bx-x {
                                font-size: 26px;
                            }
                        }

                    }

                    .a {
                        // overflow: hidden;
                        // height: 0;
                        display: flex;
                        flex-direction: column;
                        row-gap: 30px;
                        padding: 0 1em 0 3.3em;

                        a {
                            color: #A4AFC1;

                            div {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                & p:first-child {
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;
                                }
                                
                                span {
                                    color: #fff;
                                    padding: 10px 20px;
                                    border-radius: 5px;
                                    background-color: #1D9DA0;
                                }
                            }
                        }
                    }

                    .a-opened {
                        padding: 1em 1em 2em 3.3em;
                        height: initial;
                    }
                    
                    .arrow-rotated {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .aboutLessons {
            background-color: #F5FAFF;
            padding: 15px;

            .moreLessons {
                margin-top: 20px;

                p {
                    display: flex;
                    gap: 20px;

                    img {
                        width: 20px;
                    }
                }
            }
        }
    }

    .second {
        width: 30%;
        box-shadow: 0 0 6px #0000000a,
                    0 0 12px #0000000a,
                    0 0 24px #0000000a;
        border-radius: 7px;
        padding: 10px 25px;
        height: 440px;
    
        .rating {
            display: flex;
            justify-content: space-between;
            
            .ratingStars {
                text-align: left;
                color: #A4AFC1;
                
                img {
                    width: 20px;
                    height: 20px;
                }
    
                span {
                    font-size: 11px;
                }
            }
        }

        .ratingContainer {
            background-color: #F5FAFF;
            padding: 5px 15px;
            border-radius: 7px;
            margin-top: 20px;

            .ratingLine {
                display: flex;
                gap: 10px;
                align-items: center;
                color: #A4AFC1;
                font-size: 15px;
                margin: 35px 0;

                img {
                    width: 24px;
                }

                .line {
                    width: 80%;
                    height: 4px;
                    border-radius: 3px;
                    background-color: #DEDEDE;
                    position: relative;

                    span {
                        background-color: #A4AFC1;
                        // width: 70%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}

#testCc {
    background-color: #f5faff;
}

#testCc li {
    width: 100%;
    height: 100%;
    padding: 0;
}

#testCc a {
    color: #000;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    font-weight: 600;
}