.footer-wrapper {
  padding: 60px 0 30px;
  background: #070f34;
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__brand {
      margin-bottom: 35px;
    }
    &__helplines {
      font-family: Poppins;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #d9dbe1;
    }
    &__helplines:nth-child(3) {
      margin-bottom: 45px;
    }

    &__socials {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 85px;
      div {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #070f34;
        padding: 0 10px;
        a {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        border-top: 1px solid rgba(#fff, 0.2);
      }
    }
    &__rights {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #d9dbe1;
    }
  }
}
@media (max-width: 767px) {
  .footer-wrapper {
    padding: 25px 0;
  }
}
@media (max-width: 991px) {
  .footer-wrapper {
    padding-bottom: 70px;
    .footer {
      &__socials {
        margin-bottom: 35px;
      }
    }
  }
}

.footer-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 200px;
    height: 100px;
    object-fit: contain;
    margin-right: 30px;
    path {
      fill: white !important;
      color: white;
    }
  }
  svg {
    path {
      fill: white !important;
      color: white;
    }
  }
}


@media screen and (max-width: 500px) {
  .footer-images {
    flex-wrap: wrap;
  }
}