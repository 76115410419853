.eco-inner {
  h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;

    /* or 125% */

    color: #000000;
    margin-bottom: 50px;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
  }
  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}
