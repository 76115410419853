.dashboard-section {
  padding: 36px 36px 36px 0;
  margin-left: auto;
  width: 80%;
  // float: right;
  height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .dashboard-title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #1b212d;
    margin-bottom: 16px;
  }
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: 0;

    .header-item {
      background: #f8f8f8;
      margin-right: 16px;
      height: 100px;
      width: 350px;
      &:last-child {
        margin-right: 0;
      }
      border-radius: 8.32639px;
      padding: 25px;
      display: flex;
      .icon {
        margin-right: 20px;
      }
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 0;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
}
