.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .img {
    object-fit: contain;
  }
  .links {
    display: flex;
    justify-content: space-between;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6e849a;
      margin-right: 33px;
      position: relative;
      &.active {
        &::before {
          content: "";
          bottom: -10px;
          left: 0;
          position: absolute;
          width: 100%;
          height: 4px;
          background: #1d9da0;
          border-radius: 108px;
        }
      }
    }
    .signup {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: #000000;
      border-radius: 10px;
      padding: 16px 28px;
      align-items: center;
      text-align: center;
      color: #f8f6f6;
    }
  }
}


@media screen and (max-width: 500px) {
  .links {
    display: none !important;
  }
}