.carousel-inner-zamin {
    display: flex;
}
.carousel {
    box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 40px;
}
.zamin-eco {
    form {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 20px;
        input {
            // width: 250px;
            height: 52px;
        }
        .check {
            input {
                height: 18px !important;
            }
        }
        select {
            height: 52px;
        }
    }
}
.registered {
    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        /* or 120% */
        margin-bottom: 20px;
        color: #232857;
    }
    span {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        /* or 120% */
        // border: 1px solid red;
        width: 100%;
        border-radius: 12px;
        max-width: 250px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        // -webkit-box-shadow: -4px 13px 51px 33px rgba(34, 60, 80, 0.2);
        // -moz-box-shadow: -4px 13px 51px 33px rgba(34, 60, 80, 0.2);
        // box-shadow: -4px 13px 51px 33px rgba(34, 60, 80, 0.2);

        color: #232857;
    }
}
.carousel-inner-zamin {
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
    padding: 40px;
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 10px;
    }
    .img {
        img {
            height: 100%;
        }
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 20px;
    }
    button {
        background: linear-gradient(95.57deg, #1d9da0 -37.71%, #7378ee 137.71%);
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 15px 30px;
        overflow: none;
        border: none;
        display: flex;
        align-items: center;
        text-align: center;
        color: #f8f6f6;
    }
}

.cards {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.form-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .check {
        // width: 50%;
        margin: 0 auto;
        input {
            height: 18px !important;
        }
    }
}
.form-check {
    display: flex !important;
    input {
        margin-right: 14px;
    }
}

.countup {
    display: flex;
    width: 30%;
    margin-left: auto;
    align-items: center;
}

.countup-num {
    text-align: center;
    font-size: 128px;
    margin: 0 auto;
    color: green;
}

.imessage {
    max-width: 1200px;
    width: 85vw;
    height: 70vh;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("../assets/images/imessage.png");
}
