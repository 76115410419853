.category-cards {
  display: flex;
  margin-bottom: 20px;

  .category-card {
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #a6a6a6;
      margin: 0;
    }
    margin-right: 12px;
    padding: 8px 20px;
    border-radius: 8px;
    background: #f5f5f5;
    cursor: pointer;
    &.active {
      h2 {
        color: white;
      }
      background-color: #000;
    }
  }
}

.videos {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.image-cover {
  input {
    display: none;
  }
  border: 1px dashed #d3d3d3;
  border-radius: 8px;
  padding: 60px 130px;
  margin-bottom: 12px;
  display: block;
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.5);
  }

  //   height: 150px;
}
.modal-content {
  padding: 20px;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #000000;
  }
}
.save-btn {
  background: #00b2b3;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #ffffff;
  &:hover {
    background: #00b2b3;
  }
}
.close-btn {
  background: #f5f5f5;
  border-radius: 4px;
  font-style: normal;
  padding: 15px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #292929;
  border: none;
  &:hover {
    background: #f5f5f5;
    color: #292929;
  }
}
.modal-btns {
  display: flex;
}

.youtube-container {
  position: absolute;
  left: 20%;
  right: 10%;
  top: 10%;
  width: 60%;
  height: 70%;
}

.date-input {
  position: relative;
  .before {
    left: 11px;
    top: 33px;
    background: #fff;
    padding: 11px 0 0 0;
    position: absolute;
    width: 22%;
    margin: 0;
  }
}
