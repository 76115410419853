.swiper {
  // width: 100%;
  // height: 100%;
  background: transparent;
  padding: 20px;
  background-color: transparent;
}
.news-creative {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 50%;
  background: transparent;
}
.cards-sections {
  // width: 60%;
  display: flex;
  justify-content: space-between;
  // margin-left: auto;
  background-color: transparent;
}

// .swiper-slide {
//   transition: all ease 0.5s;
//   .zamin-card {
//     height: 360px;
//     padding: 10px 30px;
//     img {
//       object-fit: contain;
//       height: 110px;
//     }
//     h2 {
//       font-size: 14px;
//       margin-bottom: 10px;
//     }
//     button {
//       font-size: 14px;
//       padding: 10px;
//     }
//   }
//   &.swiper-slide-active {
//     transition: all ease 0.5s;
//     .zamin-card {
//       height: 380px;
//       img {
//         height: 130px;
//       }
//       h2 {
//         margin-bottom: 15px;
//       }
//     }
//   }
//   width: 50%;
// }

.home {
  // display: flex;
  .button {
    .form-check {
      display: flex;
    }
    input {
      margin-left: 100px;
    }
  }
  form {
    button {
      width: 50%;
      margin: 0 auto;
    }
    .form-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    input {
      // width: 250px;
      height: 52px;
    }
    .check {
      // width: 50%;
      margin: 0 auto;
      input {
        height: 18px !important;
      }
    }

    select {
      height: 52px;
    }
  }
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 180px;
  margin-top: 139px;
  .home-inner {
    // display: flex;
    width: 100%;
    // overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .titles {
    width: 40%;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px;
      color: #232857;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 50px;
      line-height: 26px;
      color: #6e849a;
    }
    .play-icon {
      background-color: transparent;
      border: none;
      align-items: center;
    }
  }
}

.home-section {
  // max-width: 100%;
}
.firsec {
  display: flex;
  padding: 60px 0;
  justify-content: space-between;
  &__left,
  &__right {
    flex: 0 0 47%;
  }
  &__left {
    padding-right: 20px;
    .section-title {
      font-family: Poppins;
      font-size: 46px;
      line-height: 70px;
      margin-bottom: 20px;
      text-align: start;
    }
    .section-desc {
      font-family: Poppins;
      font-size: 18px;
      margin-bottom: 40px;
      text-align: start;
      &.sec {
        margin-bottom: 40px;
      }
    }
    &--buttons {
      display: flex;
      align-items: center;
    }
    &--videobtn {
      display: flex;
      align-items: center;
      margin-left: 30px;
      cursor: pointer;
      img {
        margin-right: 15px;
      }
    }
  }

  &__right {
    img {
      max-width: 100%;
    }
  }
  &__left.sec {
    padding-right: 0;
  }
}

.section-title {
  // font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #212529;
  margin-bottom: 20px;
  text-align: center;
}

.section-desc {
  // font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  color: #6e849a;
}

.thirdsec {
  &__title {
    position: relative;
    margin-bottom: 80px;
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 0;
      width: 112px;
      height: 113px;
      z-index: 1;
      background-position-y: -20px;
      background-position-x: -60px;
      background-repeat: no-repeat;
    }
    &::before {
      left: 40px;
      background-image: url("../assets/images/rocket.svg");
    }
    &::after {
      background-position-x: unset;
      right: 0;
      background-image: url("../assets/images/boy-head.svg");
    }
  }

  &__content {
    display: flex;
    &--left,
    &--right,
    &--middle {
      flex: 0 0 33.33%;
    }
    &--middle {
      text-align: center;
      flex: 1;
      margin-top: -50px;
      img {
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

// Reason Card

.reasoncard {
  display: flex;
  margin-bottom: 80px;
  &__img {
    display: none;
    align-self: flex-start;
    width: 64px;
    height: 64px;
    background-blend-mode: soft-light, normal;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // &__img.box-0 {
  // 	background: linear-gradient(
  // 			180deg,
  // 			rgba(255, 255, 255, 0.2) 32.81%,
  // 			rgba(0, 0, 0, 0.02) 100%
  // 		),
  // 		linear-gradient(180deg, #f9d878 0%, #edcb72 100%);
  // 	background-blend-mode: soft-light, normal;
  // 	box-shadow: inset -5.38494px -5.38494px 5.38494px rgba(0, 0, 0, 0.05),
  // 		inset 5.38494px 5.38494px 5.38494px rgba(255, 255, 255, 0.2);
  // 	border-radius: 10px;
  // }
  // &__img.box-1 {
  // 	box-shadow: inset -5.38494px -5.38494px 5.38494px rgba(0, 0, 0, 0.05),
  // 		inset 5.38494px 5.38494px 5.38494px rgba(255, 255, 255, 0.2);
  // }
  // &__img.box-2 {
  // 	box-shadow: inset -5.38494px -5.38494px 5.38494px rgba(0, 0, 0, 0.05),
  // 		inset 5.38494px 5.38494px 5.38494px rgba(255, 255, 255, 0.2);
  // }
  // &__img.box-3 {
  // 	box-shadow: inset -5.38494px -5.38494px 5.38494px rgba(0, 0, 0, 0.05),
  // 		inset 5.38494px 5.38494px 5.38494px rgba(255, 255, 255, 0.2);
  // }
  &__content {
    &--title {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #212529;
      margin-bottom: 10px;
    }

    &--desc {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #6e849a;
    }
  }
}

// Gallery Section

.gallery {
  padding: 80px 0;
  .grid {
    padding-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .grid-wrapper {
    width: calc(33.33% - 15px);
    .grid-item {
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .grid-wrapper__1 {
    .grid-item:first-child {
      height: 290px;
      margin-bottom: 15px;
    }
    .grid-item:last-child {
      height: 441px;
    }
  }
  .grid-wrapper__2 {
    .grid-item:first-child {
      height: 440px;
      margin-bottom: 15px;
    }
    .grid-item:last-child {
      height: 290px;
    }
  }
  .grid-wrapper__3 {
    .grid-item:first-child {
      height: 312px;
      margin-bottom: 15px;
    }
    .grid-item:last-child {
      height: 418px;
    }
  }
}

//Partners Section
.partner {
  margin-bottom: 50px;
  .swiper-pagination {
    display: none;
  }
  &-title {
    display: flex;
    margin: 0 -20px;
    margin-bottom: 40px;
    .section-title,
    .section-desc {
      width: 50%;
      padding: 0 20px;
      text-align: start;
    }
  }
  &__img {
    text-align: center;
  }
  .swiper-wrapper {
    width: 200px !important;
  }
  .swiper-container {
    padding: 20px 0 !important;
    padding-bottom: 60px !important;
  }
  .swiper-pagination-bullet {
    width: 30px;
    height: 7px;
    border-radius: 50px;
  }
  .swiper-pagination-bullet-active {
    background: #1d9da0;
  }
}

// Courses Section

.courses-section {
  padding-top: 50px;
  .section-title,
  .section-desc {
    text-align: start;
  }
  .section-desc {
    margin-bottom: 30px;
  }
  .cardsort {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 35px;
    &__btn {
      border: 1px solid #d1d1d1;
      box-sizing: border-box;
      border-radius: 5px;
      transition: all 0.3s;
      font-family: Poppins;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #6e849a;
      cursor: pointer;
      padding: 10px 30px;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
      &.active,
      &:hover {
        border: 1px solid #1d9da0;
        box-sizing: border-box;
        color: #1d9da0;
      }
    }
  }
}

@media (max-width: 991px) {
  .firsec {
    flex-direction: column;
    &__left {
      flex: 100%;
      .section-title {
        font-size: 24px;
        line-height: 40px;
      }
    }
    &__left.sec {
      padding-left: 0;
    }
    .first-order &__right {
      display: none;
    }
  }
  .thirdsec {
    &__title {
      font-size: 24px;
      line-height: 40px;
      &::before {
        left: 0px;
        background-image: url("../assets/images/rocket.svg");
      }
    }
    &__content {
      &--middle {
        display: none;
      }
      &--left,
      &--right,
      &--middle {
        flex: 0 0 50%;
      }
    }
  }
  .section-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .firsec {
    padding: 25px 0;
    flex-direction: column;
  }
  .thirdsec {
    padding-top: 25px;
    &__title {
      margin-bottom: 25px;
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .section-title {
    font-size: 24px;
    line-height: 30px;
  }
  .reasoncard {
    margin-bottom: 25px;
    &__content {
      &--title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .courses-section {
    padding-top: 25px;
    .cardsort {
      display: flex;
      &__btn {
        width: 45%;
        align-self: stretch;
        padding: 10px;
        line-height: 24px;
      }
    }
  }

  .gallery {
    padding: 25px 0;
    .grid {
      flex-direction: column;
    }
    .grid-wrapper {
      width: 100%;
      height: auto !important;
      margin-bottom: 15px;
      .grid-item {
        height: 310px !important;
      }
    }
  }

  .partner {
    &-title {
      width: 100%;
      flex-direction: column;
      .section-title,
      .section-desc {
        width: 100%;
      }
    }
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
  iframe {
    width: 100% !important;
    height: 530px;
  }

  .modal-video-close-btn {
    display: none;
  }
}
.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.video {
  display: flex;
  .category-card {
    &.active {
      button {
        background-color: #00b2b3;
        color: white;
      }
    }
  }
  .category-sidebar {
    width: 20%;
    button {
      font-weight: 400;
      font-size: 16px;
      width: 80%;
      padding: 8px;
      background-color: #fff;
      outline: none;
      border-radius: 12px;
      border: none;
      line-height: 28px;
      color: #6e849a;
      display: -webkit-flex;
      // color: #00b2b3;
      color: #00b2b3;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 13px;
      text-align: left;
      &.acitve {
        background-color: #000;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .cards-sections {
    flex-wrap: wrap;
  }
}