.zamin-card {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 24px 30px rgba(123, 123, 123, 0.2);
  border-radius: 12px;
  flex-shrink: 0;
  height: 100%;
  img {
    width: 186px;
    object-fit: contain;
    height: 125px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 18px;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 36px;
  }
}
